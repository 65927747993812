"use client"

// Imports - Node
import Error from "next/error"

type Props = {}

function RootError({ }: Props): React.ReactNode {
	return (
		<html lang="en">
			<body>
				<Error statusCode={500} />
			</body>
		</html>
	)
}

// Exports
export default RootError
